import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import classnames from "classnames";
import MenuNav from "./MenuNav";
import { MenuItem, MENUS } from "../../../constants";
import { getActiveOrganization } from "../../../redux/selectors";
import { ROLE } from "../../../utils/enums";
import { UserModel } from "../../../utils/types";

interface IFullLayoutSidebarProps {
  sidebarCollapsed: boolean;
  onToggleSidebar(): void;
}

const FullLayoutSidebar: FC<IFullLayoutSidebarProps> = ({
  sidebarCollapsed,
  onToggleSidebar,
}) => {
  const location = useLocation();
  const [menus, setMenus] = useState<MenuItem[]>(MENUS);
  const [activeMenuText, setActiveMenuText] = useState<string>();
  const organization = useSelector(getActiveOrganization);

  useEffect(() => {
    // let newMenus = MENUS;
    // MENUS.forEach((menu) => {
    //   (menu?.subMenus || []).forEach((item) => {
    //     if (item.pinned && item.pin === 'standard') {
    //       newMenus = pinMenuItem(newMenus, menu, item);
    //     }
    //   });
    // });
    // setMenus(newMenus);

    let menu = MENUS.find(
      (item) => item.prefix && location.pathname.startsWith(item.prefix)
    );
    if (!menu) {
      menu = MENUS.find(
        (item) => item.link && location.pathname.startsWith(item.link)
      );
    }
    setActiveMenuText(menu?.text || MENUS[0].text);
  }, [location]);

  const activeMenu = useMemo(() => {
    const menu = menus.find(
      (item) => item.type === "menu" && item.text === activeMenuText
    );
    if (!menu) {
      return undefined;
    }

    const user = JSON.parse(sessionStorage.getItem("user")) as UserModel;

    const subMenus = menu.subMenus?.filter((sub_menu) => {
      if (
        sub_menu.admin_only &&
        !organization?.relationship.roles.includes(ROLE.ADMIN)
      ) {
        return false;
      }

      if (sub_menu.staff_only && !user?.is_staff) {
        return false;
      }

      if (
        sub_menu.has_role &&
        !organization?.relationship.roles.includes(sub_menu.has_role)
      ) {
        return false;
      }

      return !sub_menu.only_main_org || !organization.parent_uuid;
    });
    return {
      ...menu,
      subMenus,
    };
  }, [organization, activeMenuText, menus]);

  const pinMenuItem = (menus: MenuItem[], menu: MenuItem, item: MenuItem) => {
    if (item.pin === "toggle" && item.pinned) {
      return menus;
    }

    const id = menus.findIndex((m) => m.text === menu.text);
    const newMenus = [...menus];
    const newMenu = { ...menu };
    newMenus[id] = newMenu;
    if (menu.pin === "standard") {
      const newItem = { ...item, pinned: true };
      newMenu.subMenus = (newMenu.subMenus as MenuItem[]).map((subMenu) =>
        subMenu.text === item.text ? newItem : subMenu
      );
      newMenus.splice(id, 0, newItem);
    } else {
      newMenu.link = item.link;
      newMenu.subMenus = (newMenu.subMenus as MenuItem[]).map((subMenu) => ({
        ...subMenu,
        pinned: subMenu.text === item.text,
      }));
    }
    return newMenus;
  };

  const unpinMenuItem = (menus: MenuItem[], menu: MenuItem, item: MenuItem) => {
    if (item.pin === "toggle") {
      return menus;
    }

    const newItem = { ...item, pinned: false };
    const newMenu = {
      ...menu,
      subMenus: (menu.subMenus as MenuItem[]).map((subMenu) =>
        subMenu.text === item.text ? newItem : subMenu
      ),
    };
    return menus
      .filter((m) => m.text !== item.text)
      .map((m) => (m.text === menu.text ? newMenu : m));
  };

  const onTogglePin = (item: MenuItem) => {
    if (item.pinned) {
      const newMenus = unpinMenuItem(menus, activeMenu as MenuItem, item);
      setMenus(newMenus);
    } else {
      const newMenus = pinMenuItem(menus, activeMenu as MenuItem, item);
      setMenus(newMenus);
    }
  };

  return (
    <div
      className={classnames(
        "sidebar h-full flex overflow-visible z-[1000] lg:z-20 shadow-lg absolute lg:relative"
      )}
    >
      <MenuNav
        sidebarCollapsed={sidebarCollapsed}
        menus={menus}
        activeMenu={activeMenu}
        onSelectMenu={(menu) => setActiveMenuText(menu?.text)}
        onTogglePin={onTogglePin}
        onToggleSidebar={onToggleSidebar}
      />
    </div>
  );
};

export default FullLayoutSidebar;
