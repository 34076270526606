import React, { FC } from "react";
import { useHistory, useLocation } from "react-router";
import classnames from "classnames";
import SubMenu from "./sub-menu";
import { MenuItem } from "../../../../constants";
import { AuthService } from "../../../../services";
import { ROUTES } from "../../../../constants";

interface IMenuNavProps {
  sidebarCollapsed: boolean;
  menus: MenuItem[];
  activeMenu?: MenuItem;
  onSelectMenu(menu: MenuItem): void;
  onTogglePin(item: MenuItem): void;
  onToggleSidebar(): void;
}

const MenuNav: FC<IMenuNavProps> = ({
  sidebarCollapsed,
  menus,
  activeMenu,
  onSelectMenu,
  onTogglePin,
  onToggleSidebar,
}) => {
  const history = useHistory();
  const location = useLocation();

  const onMenuClick = (menu: MenuItem) => {
    if (menu.type === "menu") {
      onSelectMenu(menu !== activeMenu ? menu : null);
      if (menu.link) {
        history.push(menu.link);
      }
    }
  };

  const onLogout = () => {
    AuthService.logout();
    history.push(ROUTES.AUTH.LOGIN);
  };

  return (
    <div
      className={classnames(
        "bg-white py-2 flex flex-col justify-between overflow-x-hidden md:overflow-x-visible transition-all",
        sidebarCollapsed ? "w-0 md:w-58" : "w-58"
      )}
    >
      <div>
        {menus.map((menu: MenuItem, index: number) => {
          const menuId =
            menu.type === "menu"
              ? `menu-${menu.text.replace(/\W/g, "-").toLowerCase()}`
              : undefined;
          const isActive =
            menu.text === activeMenu?.text ||
            (!activeMenu &&
              menu.type === "menu" &&
              location.pathname.startsWith(menu.prefix));
          return (
            <div key={index} id={menuId} className={classnames("px-3")}>
              <div
                onClick={() => onMenuClick(menu)}
                className={classnames(
                  "flex items-center cursor-pointer space-x-2 px-4 py-3 rounded-md",
                  isActive ? "bg-gray-200" : "bg-white"
                )}
              >
                {menu.icon &&
                  menu.icon({ color: !isActive ? "#A8AEB7" : "primary" })}
                <div
                  className={classnames(
                    "text-blue-dark text-md",
                    isActive ? "font-semibold" : "font-normal"
                  )}
                >
                  {menu.text}
                </div>
              </div>
              <div className="ml-6">
                {isActive && (
                  <SubMenu
                    sidebarCollapsed={sidebarCollapsed}
                    menu={menu}
                    onTogglePin={onTogglePin}
                    onToggleSidebar={onToggleSidebar}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className={classnames("px-4 space-y-4")}>
        <div
          onClick={onLogout}
          className={classnames(
            "text-blue-dark cursor-pointer text-md font-semibold"
          )}
        >
          Log out
        </div>
        <div className={classnames("text-2xs mt-1")}>V 1.1.1</div>
      </div>
    </div>
  );
};

export default MenuNav;
